<template>
  <div
    ref="container"
    :style="{ minHeight: `${currentMinHeight}px` }"
  >
    <slot v-if="showSlot" />
  </div>
</template>

<script>
import { computed, ref, toRefs, watch } from "vue"
import { useElementVisibility } from "@vueuse/core"

/**
 * @component EvaLazy
 *
 * A lazy-loading component that only renders its content when visible in the viewport.
 *
 * @example Usage:
 * <eva-lazy :min-height="200" :unload-when-not-visible="true">
 *   <template #default>
 *     <your-component />
 *   </template>
 * </eva-lazy>
 *
 * @important When using this component with unload-when-not-visible=true, you MUST wrap your content
 * in <template #default> tags to ensure proper component unmounting and memory cleanup. Otherwise,
 * Vue may maintain references to child components even when they're not visible, preventing garbage collection.
 *
 * The #default slot template ensures that the component's entire subtree is properly destroyed
 * when the v-if condition becomes false, rather than just detaching it from the DOM.
 */
export default {
  props: {
    minHeight: {
      type: Number,
      default: 100,
    },
    xThreshold: {
      type: Number,
      default: 100,
    },
    yThreshold: {
      type: Number,
      default: 100,
    },
    disableLazyLoading: {
      type: Boolean,
      default: false,
    },
    unloadWhenNotVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { xThreshold, yThreshold, disableLazyLoading, unloadWhenNotVisible, minHeight } = toRefs(props)
    const container = ref(null)
    const isVisible = useElementVisibility(container, {
      xThreshold,
      yThreshold,
    })
    const hasBeenVisible = ref(false)

    const lastHeightWhenVisible = ref(null)

    // Watch for visibility changes and update lastHeightWhenVisible
    // when the element transitions from visible to not visible
    watch(isVisible, (newValue, oldValue) => {
      if (oldValue === true && newValue === false) {
        lastHeightWhenVisible.value = container.value.clientHeight
      }
      if (newValue === true) {
        hasBeenVisible.value = true
      }
    })

    // Determine whether to show the slot content based on visibility and configuration
    const showSlot = computed(() => {
      if (disableLazyLoading.value) return true
      if (unloadWhenNotVisible.value) return isVisible.value
      return isVisible.value || hasBeenVisible.value
    })

    const currentMinHeight = computed(() => {
      if (!showSlot.value) return lastHeightWhenVisible.value || minHeight.value || 0
      return minHeight.value || 0
    })

    return {
      container,
      lastHeightWhenVisible,
      showSlot,
      currentMinHeight,
    }
  },
}
</script>
